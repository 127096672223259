<template>
  <div>
    <v-card flat>
      <v-card-title class="tituloForm">Envio de Documentos (Opcional)</v-card-title>
      <v-card-text>
        <p class="text-justify">
          Por favor, escolha os arquivos para fazer o upload. Os arquivos podem
          ser nos formatos <b>pdf, jpeg, jpg ou png</b> e você pode fazer upload de até
          <b>20 arquivos com o tamanho máximo de 10mb cada</b>.
          <br/>
        </p>
        <p class="text-justify" v-if="mensagemAviso">
          Aviso: Só <b>serão considerados</b> no recurso os <b>últimos documentos enviados</b>. Quaisquer envios anteriores serão descartados.
        </p>
        <file-input
            v-model="arquivosEscolhidos"
            :max-file-size="10485760"
            :num-max-files="20"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import FileInput from "@/components/base/FileInput";

export default {
  name: "EnvioDocumentosRecurso",
  components: {FileInput},
  props: {
    mensagemAviso: {type: Boolean, required: true}
  },
  data() {
    return {
      arquivosEscolhidos: []
    };
  },

  watch: {
    arquivosEscolhidos: {
      handler() {
        this.$emit("input", this.arquivosEscolhidos);
      },
      deep: true
    }
  }
};
</script>

import Vue from 'vue'

export default {
    recuperarDocumentacaoPendentePorInscricao(inscricaoOid) {
        return Vue.axios.get("documentacaoPendente/" + inscricaoOid);
    },

    recuperarDocumentacaoPendentePorUsuarioLogado(processoOid) {
        return Vue.axios.get("documentacaoPendente/logado", {
            params: {
                processoOid: processoOid
            }
        });
    },


    atualizarDocumentacaoPendenteEnvioDocumentos(documentacaoPendenteOid) {
        return Vue.axios.put("documentacaoPendente/envioDocumentos/" + documentacaoPendenteOid);
    },

    atualizarDocumentacaoPendente(oid, textoPendencia) {
        let documentacaoPendente = {
            oid: oid,
            pendencia: textoPendencia
        }

        return Vue.axios.put("documentacaoPendente/", documentacaoPendente);
    },

    criarDocumentacaoPendente(inscricaoOid, textoPendencia) {

        let documentacaoPendente = {
            pendencia: textoPendencia,
            enviouDocumentos: false
        }

        return Vue.axios.post("documentacaoPendente/" + inscricaoOid, documentacaoPendente);
    },
}
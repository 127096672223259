<template>
  <v-container v-if="carregou" fluid>
    <div v-if="documentacaoPendente">
      <v-row justify="center">
        <v-col cols="auto">
          <v-card width="1200">
            <v-card-title class="justify-center titulo">
              {{ processo.nome }}
            </v-card-title>
            <v-divider/>
            <div>
              <v-card-subtitle class="text-center">
                <v-icon color="accent"> mdi-clipboard-edit-outline</v-icon>
                <h2 style="color: #f28c00">Documentação Pendente</h2>
              </v-card-subtitle>
              <v-card-text>
                <v-card flat>
                  <v-form ref="recursoForm">
                    <v-card-text class="mx-2">
                      <v-sheet class="pa-2" outlined>
                        <p style="white-space: pre-line" class="text-justify">
                          {{ documentacaoPendente.pendencia }}
                        </p>
                      </v-sheet>
                    </v-card-text>

                      <v-alert outlined type="warning">
                        {{ documentacaoPendente.enviouDocumentos
                          ? 'Você já fez o envio de sua documentação pendente, faça o envio novamente apenas se esqueceu de enviar algum documento.'
                          : 'Envie apenas a documentação que estiver pendente.'
                        }}
                        Atenção: O envio dos documentos não poderá ser desfeito.
                      </v-alert>

                      <EnvioDocumentosRecurso v-model="arquivosEscolhidos"
                                              :mensagemAviso="false"/>

                      <v-card-actions class="justify-center">
                        <v-btn  @click="salvarDocumentos()" color="primary">
                          Enviar Documentos
                        </v-btn>
                      </v-card-actions>
                  </v-form>
                </v-card>
              </v-card-text>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row
          align="center"
          class="mt-10"
          justify="center"
      >
        <v-alert type="info" prominent colored-border border="left" elevation="1">
          Não há necessidade ou possibilidade de você adicionar documentos a este processo
        </v-alert>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import processoService from "@/services/processo.service";
import documentacaoPendenteService from "@/services/documentacaoPendente.service";
import EnvioDocumentosRecurso from "@/components/pages/Inscricao/EnvioDocumentosRecurso.vue";
import inscricaoService from "@/services/inscricao.service";
import documentosService from "@/services/documentos.service";
import rules from "@/commons/rules"
import {mapActions} from "vuex";

export default {
  name: "DocumentacaoPendente",

  components: {
    EnvioDocumentosRecurso
  },

  data() {
    return {
      processo: null,
      documentacaoPendente: null,
      carregou: false,
      arquivosEscolhidos: [],
      inscricao: null
    };
  },

  async created() {
    let loader = this.$loading.show();

    await processoService
        .recuperarProcessoAbertoParaDocumentacaoPendente(this.$route.params.oid)
        .then(response => {
          this.processo = response.data;
        })
        .catch(() => {
          this.$router.push({name: "404"}).catch(() => {
          });
        });

    await this.recuperarDocumentacaoPendente();

    if (!this.documentacaoPendente) {
      this.exibirAviso({
        mensagem: "Você não possui documentação pendente!",
        tipo: "info"
      });
      loader.hide();
      await this.$router.push("/");
    }

    await inscricaoService.recuperarInscricaoEspecificaUsuarioLogado(this.processo.oid)
        .then(response => {
          this.inscricao = response.data;
        })

    this.carregou = true;
    loader.hide();
  },

  methods: {
    ...rules,
    ...mapActions("avisos", ["exibirAviso"]),

    async recuperarDocumentacaoPendente() {
      await documentacaoPendenteService
          .recuperarDocumentacaoPendentePorUsuarioLogado(this.processo.oid)
          .then(async response => {
            this.documentacaoPendente = response.data;
          })
          .catch(() => {
          });
    },

    async salvarDocumentos() {

        let loader = this.$loading.show();

        if (this.arquivosEscolhidos.length === 0) {
          this.exibirAviso({
            mensagem: "Nenhum documento a enviar",
            tipo: "warning"
          });
        } else {
          await documentosService.uploadDocumentosCandidato(this.arquivosEscolhidos, this.inscricao.inscricao)
              .then(async () => {

                await documentacaoPendenteService.atualizarDocumentacaoPendenteEnvioDocumentos(this.documentacaoPendente.oid);

                this.exibirAviso({
                  mensagem: "Documentos enviados com sucesso!",
                  tipo: "success"
                });

                loader.hide();

                await this.$router.push("/");
              }).catch(() => {
                this.exibirAviso({
                  mensagem: "Erro ao enviar documentos!",
                  tipo: "error"
                });
              })
        }
        loader.hide();

      }
    }

};
</script>
